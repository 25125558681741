import {Link} from "gatsby";
import React, {FC} from "react";
import Code from "../../components/code";
import Layout from "../../components/layout/layout-sidebar";
import Seo from "../../components/seo";
import {sidebarItemsGettingStarted} from "../../data/sidebar-items-getting-started";
import {apiDocsSlug} from "../../helpers/slug-helper";

const SearchPage: FC = () => (
    <Layout sidebarItems={sidebarItemsGettingStarted}>
        <Seo
            title="Getting started with searching"
            description="Instructions for searching cells or positions that match certain criteria in a grid."
        />
        <section className="p-8">
            <h1>{`Searching in a grid`}</h1>

            <h2>{`Finding a cell`}</h2>
            <p>
                {`Use the `}
                <Link to={apiDocsSlug("findCell")}>findCell</Link>
                {` function to find the cell that first matches the specified search function.`}
            </p>
            <Code code={codeExample1} language="javascript" />

            <h2>{`Finding a position`}</h2>
            <p>
                {`Use the `}
                <Link to={apiDocsSlug("findPosition")}>findPosition</Link>
                {` function to find the position that first matches the specified search function.`}
            </p>
            <Code code={codeExample2} language="javascript" />
        </section>
    </Layout>
);

export default SearchPage;

const codeExample1 = `import {createGridFromArray2D} from "gridl/core";
import {findCell} from "gridl/search";

const grid = createGridFromArray2D([
    [1, 1, 1, 4],
    [5, 6, 2, 8],
    [0, 2, 3, 4],
]);

findCell((v) => v > 2, grid); // => 4`;

const codeExample2 = `import {findPosition} from "gridl/search";

const grid = createGridFromArray2D([
    [1, 1, 1, 4],
    [5, 6, "test", 8],
    [1, "test", 3, 4],
]);

findPosition((v) => v > 2, grid); // => {x: 3, y: 0}
findPosition((v) => typeof v === "string", grid); // => {x: 2, y: 1}`;
